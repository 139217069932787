import React, { useMemo, useState } from "react";
import { debug, swalConfirm } from 'utils';
import { useTranslation } from "react-i18next";
import { HtmlElement } from './../../../../../helpers/HtmlElements/HtmlElements';
import { FaTrash } from "react-icons/fa";
import SymptomsSelector from "../helpers/SymptomsSelector";
import AdaptationsSelector from "../helpers/AdaptationsSelector";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { AiOutlinePlusCircle } from "react-icons/ai"
import Indicator from "../helpers/Indicator";
const categories = [
  { key: "roots", value: "racine / collet", name: "Racines / Collet" },
  { key: "trunk", value: "tronc", name: "Troncs" },
  { key: "branches_large", value: "branche >25cm", name: "Branches >25cm" },
  { key: "branches_small", value: "branche <25cm", name: "Branches <25cm" },
];
const TreeFormDiags = ({ item, onChange }) => {
  const { t } = useTranslation();
  // Calcul des occurrences via useMemo
  const occurrences = useMemo(() => {
    const result = {};
    categories.forEach(({ key }) => {
      const keys = Object.keys(item.meta).filter((field) =>
        field.startsWith(`diags/${key}/`)
      );
      const maxIndex = keys.reduce((max, field) => {
        const match = field.match(/diags\/\w+\/(\d+)\//);
        return match ? Math.max(max, parseInt(match[1], 10)) : max;
      }, 0);
      result[key] = Math.max(maxIndex, 1); // Au moins une occurrence par catégorie
    });
    return result;
  }, [item.meta]);
  // Ajouter une occurrence
  const addOccurrence = (key) => {
    const newIndex = (occurrences[key] || 0) + 1;
    const updatedMeta = {
      ...item.meta,
      [`diags/${key}/${newIndex}/symptoms`]: [],
      [`diags/${key}/${newIndex}/evaluation_symptome`]: "",
      [`diags/${key}/${newIndex}/adaptations`]: [],
      [`diags/${key}/${newIndex}/qualite_adaptation`]: "",
    };
    onChange({ target: { name: "meta", value: updatedMeta } });
  };
  // Supprimer une occurrence
  const removeOccurrence = async (key, index) => {
    if (occurrences[key] > 1) {
      const result = await swalConfirm({ message: "Confirmez vous la suppression ?" });
      if (result) {
        const updatedMeta = Object.fromEntries(
          Object.entries(item.meta).filter(
            ([field]) => !field.startsWith(`diags/${key}/${index + 1}/`)
          )
        );
        onChange({ target: { name: "meta", value: updatedMeta } });
      }
    }
  };
  // Gestion des modals (symptômes et adaptations)
  const [modal, setModal] = useState({
    type: null,
    category: null,
    idx: null,
    currentValues: [],
  });
  const openModal = (type, category, idx) => {
    const path = `diags/${category}/${idx + 1}/${type}`;
    setModal({
      type,
      category,
      idx,
      currentValues: item.meta[path] || [],
    });
  };
  const handleModalChange = (newValues) => {


    const path = `diags/${modal.category}/${modal.idx + 1}/${modal.type}`;
    const updatedMeta = {
      ...item.meta,
      [path]: newValues,
    };
    console.log(newValues)




    // Si le type est "symptoms" et aucun symptôme n'est reporté, forcer `evaluation_symptome` à aucun
    if (modal.type === "symptoms" && newValues.length === 0 || newValues.includes("Aucun")) {
      const evaluationPath = `diags/${modal.category}/${modal.idx + 1}/evaluation_symptome`;
      updatedMeta[evaluationPath] = "Aucun";
      console.log('On passe ici')

      // Si qualite_adaptation est vide, forcer qualite_adaptation à "Aucune"
      const qualityPath = `diags/${modal.category}/${modal.idx + 1}/qualite_adaptation`;
      console.log(qualityPath, updatedMeta[qualityPath])
      if (!item.meta[qualityPath]) {
        updatedMeta[qualityPath] = 'Aucune';
      }

      // Si cible_menacee est vide, forcer cible_menacee à "Aucune"
      const targetPath = `diags/${modal.category}/${modal.idx + 1}/cible_menacee`;
      console.log(updatedMeta[targetPath, targetPath])
      if (!item.meta[targetPath]) {
        updatedMeta[targetPath] = "Aucune";
      }
      // Si niveau_menace est vide, forcer niveau_menace à "Aucun"
      const levelPath = `diags/${modal.category}/${modal.idx + 1}/niveau_menace`;
      console.log(updatedMeta[levelPath, levelPath])
      if (!item.meta[levelPath]) {
        updatedMeta[levelPath] = "Aucun";
      }
    }
    // Si le type est "adaptations" et aucune adaptation n'est reportée, forcer `qualite_adaptation` à aucune
    if (modal.type === "adaptations" && (newValues.length === 0 || newValues.includes("Aucune"))) {
      const qualityPath = `diags/${modal.category}/${modal.idx + 1}/qualite_adaptation`;
      updatedMeta[qualityPath] = "Aucune";
    }


    onChange({ target: { name: "meta", value: updatedMeta } });
    setModal({ type: null, category: null, idx: null, currentValues: [] });
  };
  return (
    <div id="TreeFormDiags">
      <table className="table table-bordered table-sm">
        <thead>
          <tr>
            <th className="bg-green-14">Symptôme</th>
            <th className="bg-green-14">Évaluation<br />du symptôme</th>
            <th className="bg-green-14">Orientation</th>
            <th className="bg-green-14">Hauteur<br />symptôme</th>
            <th className="bg-green-14">Adaptation</th>
            <th className="bg-green-14">Qualité de<br />l'adaptation</th>
            <th className="bg-green-14">Cible menacée</th>
            <th className="bg-green-14">Niveaux<br />de menace</th>
            <th className="bg-green-14" colSpan="4">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {categories.map(({ key, value, name }) => (
            <React.Fragment key={key}>
              <tr>
                <th colSpan="12" className="text-start">
                  <div className="d-flex">
                    <h6>{name}</h6>
                    <a
                      className="pointer text-decoration-none text-green-6 fw-bold ms-2"
                      onClick={() => addOccurrence(key)}
                      title={`Ajouter 1 ${value}`}
                    >
                      <AiOutlinePlusCircle className="mr-1" size={16} />
                    </a>
                  </div>
                </th>
              </tr>
              {Array.from({ length: occurrences[key] }).map((_, idx) => {
                const isStriped = idx % 2 === 0; // Pair = true, Impair = false
                return (
                  <tr key={`${key}-${idx}`}
                    className={isStriped ? "striped" : ""}
                  >
                    {/* Symptômes */}
                    <td className="text-center">
                      <div>
                        {(() => {
                          try {
                            const symptoms = item?.meta?.[`diags/${key}/${idx + 1}/symptoms`];
                            if (Array.isArray(symptoms)) {
                              return symptoms.length > 0 ? symptoms.join(", ") : t("Non précisé");
                            } else {
                              return t("Non précisé");
                            }
                          } catch (error) {
                            console.error("Erreur lors de l'accès aux symptômes :", error);
                            return t("Erreur");
                          }
                        })()}
                      </div>
                      <a className="text-green-6" onClick={() => openModal("symptoms", key, idx)}>{t("Modifier")}</a>
                    </td>
                    {/* Évaluation */}
                    <td>
                      <HtmlElement.select
                        options={["Aucun", "Léger", "Moyen", "Important", "Très important"]}
                        placeholder="> Évaluation du symptôme"
                        name={`meta.diags/${key}/${idx + 1}/evaluation_symptome`}
                        value={item.meta[`diags/${key}/${idx + 1}/evaluation_symptome`] || ""}
                        onChange={onChange}
                        size="xs"
                        disabled={
                          !item.meta[`diags/${key}/${idx + 1}/symptoms`]?.length ||
                          (item.meta[`diags/${key}/${idx + 1}/symptoms`] || []).includes("Aucun")
                        }
                      />
                    </td>
                    {/* Orientation */}
                    <td>
                      <HtmlElement.select options={["Nord", "Nord-Est", "Est", "Sud-Est", "Sud", "Sud-Ouest", "Ouest", "Nord-Ouest"]}
                        placeholder="&gt; Orientation"
                        name={`meta.diags/${key}/${idx + 1}/orientation`}
                        value={item.meta[`diags/${key}/${idx + 1}/orientation`] || ""}
                        onChange={onChange}
                        size="xs"
                        disabled={
                          !item.meta[`diags/${key}/${idx + 1}/symptoms`]?.length ||
                          (item.meta[`diags/${key}/${idx + 1}/symptoms`] || []).includes("Aucun")
                        }

                      />
                    </td>
                    {/* Hauteur */}
                    <td className="text-center">
                      <div className="input-group input-group-xs" style={{ width: "80px" }}>
                        <input
                          type="number"
                          className="form-control form-control-xs text-end"
                          name={`meta.diags/${key}/${idx + 1}/hauteur_symptome`}
                          value={item.meta[`diags/${key}/${idx + 1}/hauteur_symptome`] || ""}
                          onChange={onChange}
                          placeholder="Hauteur (m)"

                        />  <span className="input-group-text">M.</span>
                      </div>
                    </td>
                    {/* Adaptations */}
                    <td className="text-center">
                      <div>
                        {(() => {
                          try {
                            const adaptations = item?.meta?.[`diags/${key}/${idx + 1}/adaptations`];
                            if (Array.isArray(adaptations)) {
                              return adaptations.length > 0 ? adaptations.join(", ") : t("Non précisée");
                            } else {
                              return t("Non précisée");
                            }
                          } catch (error) {
                            console.error("Erreur lors de l'accès aux adaptations :", error);
                            return t("Erreur");
                          }
                        })()}
                      </div>

                      <a className="text-green-6" onClick={() => openModal("adaptations", key, idx)}>{t("Modifier")}</a>
                    </td>
                    {/* Qualité des adaptations */}
                    <td>
                      <HtmlElement.select
                        options={["Très bonne", "Bonne", "Moyenne", "Faible", "Aucune"]}
                        placeholder="> Qualité"
                        name={`meta.diags/${key}/${idx + 1}/qualite_adaptation`}
                        value={item.meta[`diags/${key}/${idx + 1}/qualite_adaptation`] || ""}
                        onChange={onChange}
                        size="xs"
                        disabled={
                          !item.meta[`diags/${key}/${idx + 1}/adaptations`]?.length ||
                          item.meta[`diags/${key}/${idx + 1}/adaptations`]?.includes("Aucune")
                        }
                      />
                    </td>
                    {/* Cible menacée */}
                    <td>
                      <HtmlElement.select options={["Aucune", "Piétons", "Véhicules", "Occupation", "Dommage matériel"]}
                        placeholder="> Cible menacée"
                        name={`meta.diags/${key}/${idx + 1}/cible_menacee`}
                        value={item.meta[`diags/${key}/${idx + 1}/cible_menacee`] || ""}
                        onChange={onChange}
                        size="xs"
                      />
                    </td>
                    {/* Niveau de menace */}
                    <td>
                      <HtmlElement.select options={["Aucun", "Faible", "Modéré", "Élevé"]}
                        placeholder="> Niveau" name={`meta.diags/${key}/${idx + 1}/niveau_menace`}
                        value={item.meta[`diags/${key}/${idx + 1}/niveau_menace`] || ""}
                        onChange={onChange}
                        size="xs"
                      />
                    </td>

                    {/*
                    <td >
                      <Indicator
                        type="physiologie"
                        value={item.meta[`diags/${key}/${idx + 1}/physiologie`]}
                        labelKey="Etat physiologique"
                      />
                    </td>
                      */}
                    <td >
                      <Indicator
                        type="mecanique"
                        value={item.meta[`diags/${key}/${idx + 1}/mecanique/letter`]}
                        labelKey="Risque mécanique"
                      />
                    </td>
                    <td >
                      <Indicator
                        type="risque"
                        value={item.meta[`diags/${key}/${idx + 1}/risque/letter`]}
                        labelKey="Niveau de risque"
                      />
                    </td>
                    {/* Actions */}
                    <td className="text-end">
                      {idx > 0 && (
                        <FaTrash
                          onClick={() => removeOccurrence(key, idx)}
                          className="text-green-6 pointer"
                        />
                      )}
                    </td>
                  </tr>
                )
              })}
              <tr>
                <td colSpan="12">
                  <hr className="m-0" />
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
      {/* Modals */}
      {modal.type === "symptoms" && (
        <SymptomsSelector
          category={modal.category}
          currentSymptoms={modal.currentValues}
          onSymptomsChange={handleModalChange}
          onClose={() => setModal({ type: null, category: null, idx: null, currentValues: [] })}
        />
      )}
      {modal.type === "adaptations" && (
        <AdaptationsSelector
          category={modal.category}
          currentAdaptations={modal.currentValues}
          onAdaptationsChange={handleModalChange}
          onClose={() => setModal({ type: null, category: null, idx: null, currentValues: [] })}
        />
      )}
      {debug(item.meta, 1)}
    </div>
  );
};
export default TreeFormDiags;

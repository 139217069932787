import React, { useEffect, useState, useMemo } from 'react';
import { Marker, Circle, Popup, Tooltip } from 'react-leaflet';
import { useSelector } from 'react-redux';
import useMarkerEventHandlers from './../../hooks/useMarkerEventHandlers';
import L from 'leaflet';
const CustomMarkerTree = ({ item }) => {
  const project = useSelector(state => state.project);
  const [showRef, setShowRef] = useState(true);
  useEffect(() => {
    const refMeta = project.metaVisibility.find(m => m.id === 'ref');
    setShowRef(refMeta ? refMeta.visible : false);
  }, [project.metaVisibility]);
  useEffect(() => {
  }, [showRef]);
  const sortedData = useMemo(() => {
    return project.metaVisibility
      .filter(mv => mv.visible && item.meta[mv.metapath])
      .sort((a, b) => item.meta[b.metapath] - item.meta[a.metapath]);
  }, [project.metaVisibility, item.meta]);
  const circles = sortedData.map((mv, idx) => (
    <Circle
      key={`mv-${mv.id}-${idx}`}
      center={item.itGeometry}
      radius={parseFloat(item.meta[mv.metapath]) / 2}
      fillColor={mv.stroke}
      color={mv.stroke}
      fillOpacity={mv.opacity ? mv.opacity : 0.1}
      weight={1}
    >
      <Tooltip direction="right" style={{ padding: '0 0' }}>{mv.tooltip}: {item.meta[mv.metapath]}m</Tooltip>
    </Circle>
  ));
  const eventHandlers = useMarkerEventHandlers(item);
  const icon = useMemo(() => L.icon({
    iconUrl: `/img/markers/${item.itType}${item.unlocked ? '' : '-lk'}.png`,
    iconSize: [17, 25],
    iconAnchor: [9, 12]
  }), [item.itType, item.unlocked]);
  return (
    <>
      <Marker
        position={item.itGeometry}
        draggable={item.unlocked}
        icon={icon}
        eventHandlers={eventHandlers}
      >
        {showRef && <Tooltip permanent >
          <span className={`mecanique_${item.meta['diags/tree/1/mecanique/letter']}`} style={{ backgroundColor: `${item.meta['diags/tree/1/mecanique/color']}` }}>
            {item.itRef}
          </span>
        </Tooltip>
        }
        {!showRef && <Tooltip>

          <span className={`mecanique_${item.meta['diags/tree/1/mecanique/letter']}`} style={{ backgroundColor: `${item.meta['diags/tree/1/mecanique/color']}` }}>
            {item.itRef}
          </span>



        </Tooltip>}
      </Marker>
      {circles}
    </>
  );
};
export default CustomMarkerTree;
/*

*/
import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap"; // Utilisation de React-Bootstrap pour la modale
import { useTranslation } from "react-i18next";
import './adaptations-selector.css';
const AdaptationsSelector = ({ category, currentAdaptations = [], onAdaptationsChange, onClose }) => {
  const { t } = useTranslation();
  // Adaptations prédéfinies intégrées dans le composant
  const predefinedAdaptations = {
    branches_large: [
      "Aucune",
      "Bois de renforcement",
      "Hauban naturel",
      "Hauban non naturel",
      "H/D faible",
      "Diamètre important"
    ],
    branches_small: [
      "Aucune",
      "Bois de renforcement",
      "Hauban naturel",
      "Hauban non naturel",
      "H/D faible",
      "Diamètre important"
    ],
    roots: [
      "Aucune",
      "Houppier commun",
      "Bois de renforcement",
      "Adaptation géométrique",
      "H/D faible",
      "Diamètre important",
      "Contrefort puissant",
      "Racine interne",
      "Hauban naturel",
      "Charge abaissée artificiellement",
      "Racine adventive"
    ],
    trunk: [
      "Aucune",
      "Bois de renforcement",
      "Adaptation géométrique",
      "H/D faible",
      "Diamètre important",
      "Contrefort puissant",
      "Racine interne",
      "Hauban naturel",
      "Charge abaissée artificiellement"
    ]
  };
  const [selectedAdaptations, setSelectedAdaptations] = useState(new Set(currentAdaptations.length ? currentAdaptations : ["Aucune"]));
  const [customAdaptation, setCustomAdaptation] = useState("");
  const [customAdaptationsList, setCustomAdaptationsList] = useState([]);
  useEffect(() => {
    // Ajouter les adaptations non prédéfinies à la liste des adaptations personnalisées
    const predefinedSet = new Set(predefinedAdaptations[category]);
    const initialCustomAdaptations = currentAdaptations.filter(adaptation => !predefinedSet.has(adaptation));
    setCustomAdaptationsList(initialCustomAdaptations);
  }, [category, currentAdaptations]);
  const handleCheckboxChange = (adaptation) => {
    const updatedAdaptations = new Set(selectedAdaptations);
    if (adaptation === "Aucune") {
      updatedAdaptations.clear();
      updatedAdaptations.add("Aucune");
    } else {
      updatedAdaptations.delete("Aucune");
      if (updatedAdaptations.has(adaptation)) {
        updatedAdaptations.delete(adaptation);
      } else {
        updatedAdaptations.add(adaptation);
      }
      // Si tout est décoché, on sélectionne "Aucune"
      if (updatedAdaptations.size === 0) {
        updatedAdaptations.add("Aucune");
      }
    }
    setSelectedAdaptations(updatedAdaptations);
  };
  const handleAddCustomAdaptation = () => {
    if (customAdaptation.trim() && !customAdaptationsList.includes(customAdaptation.trim())) {
      setCustomAdaptationsList([...customAdaptationsList, customAdaptation.trim()]);
      const updatedAdaptations = new Set(selectedAdaptations);
      updatedAdaptations.delete("Aucune");
      updatedAdaptations.add(customAdaptation.trim());
      setSelectedAdaptations(updatedAdaptations);
      setCustomAdaptation("");
    }
  };
  const handleSave = () => {
    onAdaptationsChange(Array.from(selectedAdaptations));
    onClose();
  };
  return (
    <Modal
      show
      onHide={onClose}
      backdrop="static"
      keyboard={false}
      dialogClassName="adaptations-modal"
      backdropClassName="adaptations-backdrop "
    >
      <Modal.Header closeButton>
        <Modal.Title><h6>{t("Choisir des adaptations")}</h6></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="fs-7">
          {predefinedAdaptations[category].map((adaptation, idx) => (
            <Form.Check
              key={idx}
              type="checkbox"
              id={`adaptation-${idx}`}
              label={adaptation}
              checked={selectedAdaptations.has(adaptation)}
              onChange={() => handleCheckboxChange(adaptation)}
            />
          ))}
          {customAdaptationsList.map((custom, idx) => (
            <Form.Check
              key={`custom-${idx}`}
              type="checkbox"
              id={`custom-adaptation-${idx}`}
              label={custom}
              checked={selectedAdaptations.has(custom)}
              onChange={() => handleCheckboxChange(custom)}
            />
          ))}
        </div>
        <div className="mt-3">
          <Form.Group controlId="customAdaptation" className="fs-7 mb-4">
            <Form.Label>{t("Ajouter une adaptation personnalisée")}</Form.Label>
            <div className="d-flex">
              <Form.Control
                type="text"
                placeholder={t("Nouvelle adaptation")}
                value={customAdaptation}
                onChange={(e) => setCustomAdaptation(e.target.value)}
                className="form-control-sm"
              />
              <Button variant="outline-primary" className="ms-2 btn-sm" onClick={handleAddCustomAdaptation}>
                {t("Ajouter")}
              </Button>
            </div>
          </Form.Group>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose} className=" btn-sm">
          {t("Annuler")}
        </Button>
        <Button variant="primary" onClick={handleSave} className=" btn-sm">
          {t("Enregistrer")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default AdaptationsSelector;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TreeFormFeatures from './form-tabs/TreeFormFeatures';
import TreeFormMedias from './form-tabs/TreeFormMedias';
import TreeFormSnapshots from './form-tabs/TreeFormSnapshots';
import { useTranslation } from 'react-i18next';
import TreeFormPhysiology from './form-tabs/TreeFormPhysiology';
import TreeFormDiags from './form-tabs/TreeFormDiags';
import Indicator from './helpers/Indicator';
import { useProjectMapContext } from './../../../hooks/useProjectMapContext';
import useItemsService from './../../../hooks/useItemsService';
import { HtmlElement } from './../../../../helpers/HtmlElements/HtmlElements';
import { debug, isDev, formatDate } from './../../../../../utils';
import './../../../../../css/nav-tabs.css'
import './css/tree-form.scss';
import ResponsiveNavTabs from './../../../../helpers/ResponsiveNavTabs/ResponsiveNavTabs';

const TreeFrom = ({ item = null, onUpdate }) => {
  const { t } = useTranslation();
  const {
    userCan,
    setItemSelected,
  } = useProjectMapContext();
  const {
    save_item, update_item,
  } = useItemsService();
  const project = useSelector(state => state.project);
  const [activeTab, setActiveTab] = useState(0);
  const [itemCopy, setItemCopy] = useState({ initialized: false, meta: {} });
  const [navTabs, setNavTabs] = useState();

  useEffect(() => {
    const navTabs = [
      'Caratéristiques',
      'Physiologie',
      'Mécanique',
      'Instantanés',
      'Tâches',
      'Pièces jointes'
    ]
    if (isDev()) {
      navTabs.push('Debug');
    }
    setNavTabs(navTabs)
  }, []);
  useEffect(() => {
    // Obtenir la date actuelle au format "yyyy-MM-dd" en vanilla JS


    // Vérifier si diagnosisDate est vide ou undefined
    const updatedItem = {
      ...item,

      meta: {
        ...item.meta,
        diagnosisDate: item.meta?.diagnosisDate ? item.meta.diagnosisDate : formatDate(new Date(), 'Y-m-d'),
      },
    };

    // Mettre à jour l'état avec l'élément modifié
    setItemCopy({ ...updatedItem, meta_diags_updated: true, dirty: false, });
  }, [item]);
  const handleChange = (obj) => {
    if (obj.target) {
      const { name, value } = obj.target;
      console.log(name, value);
      const parts = name.split('.');
      console.log(parts);
      let newObj;

      if (parts.length === 1) {
        newObj = { ...itemCopy, [parts[0]]: value };
      } else {
        newObj = {
          ...itemCopy,
          [parts[0]]: {
            ...itemCopy[[parts[0]]],
            [parts[1]]: value
          }
        };
        // Vérifie si parts[1] commence par "diags[1]"
        if (parts[1].startsWith("diags/")) {
          newObj = {
            ...newObj,
            meta_diags_updated: true,
          };
        }
      }
      newObj.dirty = true;
      setItemCopy(prevItem => ({ ...prevItem, ...newObj }));
    } else {
      setItemCopy(prevItem => ({ ...prevItem, ...obj, dirty: true }));
    }
    // setIsDirty(true);
  };

  const submit = async () => {
    let response = null;
    if (!itemCopy.itId || itemCopy.itId == '') {
      item = await save_item(itemCopy);
      setItemCopy({ ...itemCopy, ...item })
      onUpdate(item);
    } else {
      item = await update_item(itemCopy);
      setItemCopy({ ...itemCopy, ...item })
      onUpdate(item);
    }
    // setIsDirty(false);
    // setItemSelected(null);
  }
  return (
    <div id="TreeFrom" className='d-flex flex-column h-100 flex-grow-1'>
      <ResponsiveNavTabs tabNames={navTabs} activeTab={activeTab} setActiveTab={setActiveTab} >
        <div className='me-3 pb-1 ' style={{ height: '45px' }}>

          <div className='d-flex flex-column'>
            <label className='d-block fs-8  text-green-2'>
              Evaluation globale
            </label>
            <div className='d-flex justify-content-center flex-grow-1' >
              <div className='diag_label pe-2'>

                <Indicator
                  type="physiologie"
                  value={item?.meta?.[`physiology/score/letter`]}
                  qualification={item?.meta?.[`physiology/score/qualification`]}
                  labelKey="Etat physiologique"
                />

              </div>
              <div className='diag_label pe-2'>

                <Indicator
                  type="mecanique"
                  value={item?.meta?.[`diags/tree/1/mecanique/letter`]}
                  labelKey="Risque mécanique"
                />

              </div>
              <div className='diag_label'>

                <Indicator
                  type="risque"
                  value={item?.meta?.[`diags/tree/1/risque/letter`]}
                  labelKey="Niveau de risque"
                />

              </div>
            </div>

          </div>
        </div>
        {
          userCan('editItem') &&
          <HtmlElement.submit text="Sauvegarder" onClick={submit} className={`${itemCopy.dirty ? 'btn-danger' : 'btn-outline-primary'}`} />
        }
      </ResponsiveNavTabs >
      <div className={`tab-content flex-grow-1`} >
        <div className={`tab-pane fade show ${activeTab == 0 ? 'active' : ''}`} >
          <TreeFormFeatures item={itemCopy} onChange={handleChange} />
        </div>
        <div className={`tab-pane fade show ${activeTab == 1 ? 'active' : ''}`} >
          <TreeFormPhysiology item={itemCopy} onChange={handleChange} />
        </div>
        <div className={`tab-pane fade show ${activeTab == 2 ? 'active' : ''}`} >
          <TreeFormDiags item={itemCopy} onChange={handleChange} />
        </div>
        <div className={`tab-pane fade show ${activeTab === 3 ? 'active' : ''}`} >
          <TreeFormSnapshots item={itemCopy} />
        </div>
        <div className={`tab-pane fade show ${activeTab === 4 ? 'active' : ''}`} >
        </div>
        <div className={`tab-pane fade show ${activeTab === 5 ? 'active' : ''}`} >
          <TreeFormMedias item={itemCopy} onChange={handleChange} />
        </div>
        <div className={`tab-pane fade show ${activeTab === 6 ? 'active' : ''}`} >
          {debug(itemCopy)}
        </div>
      </div>
    </div >
  );
};
export default TreeFrom;

/*


            */
import React, { useEffect, useState } from 'react';
import API from 'api';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { HtmlElement } from '../../../../../helpers/HtmlElements/HtmlElements';
import { debug, isDev, swalAlert, swalConfirm, swalInput, formatDate } from 'utils';
import { MdRefresh, MdDownload, MdDelete } from "react-icons/md";

import './../css/snapshots.css';
const TreeFormSnapshots = ({ item, onChange }) => {
  const { t } = useTranslation();
  const [fetching, setFetching] = useState(false);
  const project = useSelector(state => state.project);
  const [snapshots, setSnapshots] = useState([]);
  useEffect(() => {
    if (item.itId) {
      fetchSnapshots();
    }
  }, [item.itId]);
  const fetchSnapshots = async () => {
    if (fetching) {
      return;
    }
    setFetching(true);
    try {
      const response = await API.get('/item/' + item.itId + '/snapshots');
      setSnapshots(response.data);
    } catch (error) {
      swalAlert(error)
      console.error('Erreur lors de la récupération des snapshots:', error);
    } finally {
      setFetching(false);
    }
  };
  const createSnapshot = async () => {
    const result = await swalConfirm({
      message: "L'instantané va être créé à la date du diagnostic : " + formatDate(item.meta.diagnosisDate),
      type: "date",
      value: new Date().toISOString().split("T")[0]
    })
    if (result) {
      const snapDate = item.meta.diagnosisDate;
      try {
        const response = API.post('/snapshot/' + item.itId, { snapDate: snapDate });; console.log(response.data)
      } catch (error) {
        swalAlert(error)
        console.error('Erreur lors de la récupération des snapshots:', error);
      }
    }
  }
  return (
    <div className='card card-snaphots flex-grow-1'>
      <div className="card-title mb-3 d-flex justify-content-betwween">
        <h6 className='m-0'>Instantanés</h6>
        <div style={{ top: "-2px" }} className="position-relative">
          <span>
            <MdRefresh onClick={fetchSnapshots} size={20} className="pointer" style={{ opacity: fetching ? '0.5' : '1' }} />
          </span>
        </div>
      </div>
      {snapshots.length === 0 &&
        <div className='my-3 text-center fs-7 text-green-4 py-2 mx-auto' style={{ maxWidth: '350px', width: '90%' }} >
          Aucun instantané trouvé pour ce sujet
        </div>
      }




      <div className="table-responsive">
        <table className="table table-striped table-sm table-bordered">
          <thead className="table-dark">
            <tr>
              <th className="text-center">Date diagnostic</th>
              <th className="text-center">Créé par</th>

              <th className="text-center">Crée le</th>
              <th></th>



            </tr>
          </thead>
          <tbody>
            {snapshots.map((snapshot) => (
              <tr key={snapshot.snapId}>

                <td className="text-center">{formatDate(snapshot.snapDate)}</td>
                <td className="text-center">{snapshot.snapCreatedBy}</td>

                <td className="text-center">{formatDate(snapshot.snapCreatedAt)}</td>
                <td className="text-end">
                  <span className="me-2" title="Charger">
                    <MdDownload className="pointer text-green-6" size={20} onClick={() => console.log("Charger")} />
                  </span>
                  <span title="Supprimer">
                    <MdDelete className="pointer  text-green-6" size={20} onClick={() => console.log("Supprimer")} />
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

      </div>


      <div className='text-center py-3'>
        <HtmlElement.submit
          text="Créer un instantané"
          onClick={createSnapshot}
          className={`btn-outline-primary px-5 `}
          disabled={!isDev() || !item.itId || item.dirty === true}
        />
        {(!item.itId || item.dirty === true) &&
          <p className='mt-5 text-red fs-8'>
            Veuillez enregistrer les modifications avant de créer un instantané.
          </p>
        }

      </div>
      {debug(snapshots)}
    </div>
  );
};
export default TreeFormSnapshots;


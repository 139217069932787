import React, { useState, useEffect } from 'react';
import { debug } from './../../../utils';
import API from './../../../api';
import InputAutocomplete from '../InputAutocomplete/InputAutocomplete'
const CitySelector = ({ city, onSelected }) => {
  const [cityCopy, setCityCopy] = useState(city);
  const [cities, setCities] = useState([]);

  const handleSuggestionSelect = (name, value) => {
    onSelected(value)
    setCityCopy({ ...value });
  }
  const hanleOnChange = async (event) => {
    const str = event.target.value
    setCityCopy({ ...cityCopy, cityName: str });
    if (str.length > 0) {
      const response = await API.get('/cities/filter?search=' + str);
      console.log(response.data)
      setCities(response.data)
    }
  }
  return (
    <div className="city-selector">
      <InputAutocomplete
        value={cityCopy.cityName}
        suggestionsSrc={cities}
        filterMode="include"
        name="city"
        displayKey="cityName"
        onChange={hanleOnChange}
        onSuggestionSelect={handleSuggestionSelect}
        formatSuggestion={(key, city) => city.cityName}
        suggestionsOnFocus={false}
        disableFilter={true}
        debug={false}
      />
    </div>
  );
};
export default CitySelector;


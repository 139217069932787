import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { debug } from '../../utils'
import { FaRegPlusSquare } from 'react-icons/fa';
const ProjectsHomeBloc = () => {
  const user = useSelector((state) => state.user);
  const canCreateProject = () => {
    return user.userRoles?.Projects == 'admin' ||
      (user?.details?.projects.length < user.details?.customer?.plan.planMaxProjects)
  }
  return (
    user?.userRoles?.Projects &&
    <div id="ProjectsHomeBloc" className="col-md-4 mb-3">
      <div className="card h-100">
        <div className="card-title">
          <h5 className="text-green-1">Mes projets</h5>
          {canCreateProject() &&
            <Link to="/project/map/new" title="Créer un projet"><FaRegPlusSquare size={18} /></Link>
          }
        </div>
        <div className="card-body">
          <div className="card-text">
            {user.details.projects.map((project, idx) => (
              <dl key={idx}>
                <dt><Link className="text-green-6" to={`/project/map/${project.projId}`}>{project.projName}</Link></dt>
                <dd className='fs-8'>
                  Créé par {project.projCreatedBy}<br />
                  {project.projAddress}
                </dd>
              </dl>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProjectsHomeBloc;

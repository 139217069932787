import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap"; // React-Bootstrap pour la modale
import { useTranslation } from "react-i18next";

const SymptomsSelector = ({ category, currentSymptoms = [], onSymptomsChange, onClose }) => {
  const { t } = useTranslation();

  // Symptômes prédéfinis
  const predefinedSymptoms = {
    branches_large: [
      "Aucun",
      "Insertion incuse",
      "Fissuration",
      "Non ovalisée",
      "H/D élevé",
      "Queue de lion"
    ],
    branches_small: [
      "Aucun",
      "Insertion incuse",
      "Fissuration",
      "Non ovalisée",
      "H/D élevé",
      "Queue de lion"
    ],
    roots: [
      "Aucun",
      "Inclinaison",
      "Soulèvement",
      "Fissuration du sol",
      "Pourriture du bois",
      "Arrachement racinaire",
      "Champignon",
      "Contrefort dégradé",
      "Absence de contrefort"
    ],
    trunk: [
      "Aucun",
      "Fissure",
      "Cavités",
      "Pourriture du bois",
      "Nécrose",
      "Inclinaison",
      "Dommages",
      "Champignon",
      "H/D élevé"
    ]
  };

  const [selectedSymptoms, setSelectedSymptoms] = useState(
    new Set(currentSymptoms.length > 0 ? currentSymptoms : ["Aucun"])
  );
  const [customSymptom, setCustomSymptom] = useState("");
  const [customSymptomsList, setCustomSymptomsList] = useState([]);

  useEffect(() => {
    // Ajouter les symptômes non prédéfinis à la liste personnalisée
    const predefinedSet = new Set(predefinedSymptoms[category] || []);
    const initialCustomSymptoms = currentSymptoms.filter(symptom => !predefinedSet.has(symptom));
    setCustomSymptomsList(initialCustomSymptoms);

    // Mettre à jour selectedSymptoms pour inclure "Aucun" par défaut si currentSymptoms est vide
    setSelectedSymptoms(new Set(currentSymptoms.length > 0 ? currentSymptoms : ["Aucun"]));
  }, [category, currentSymptoms]);


  const handleCheckboxChange = (symptom) => {
    const updatedSymptoms = new Set(selectedSymptoms);

    if (updatedSymptoms.has(symptom)) {
      updatedSymptoms.delete(symptom);
    } else {
      updatedSymptoms.add(symptom);

      // Si un symptôme autre que "Aucun" est sélectionné, désélectionner "Aucun"
      if (symptom !== "Aucun") {
        updatedSymptoms.delete("Aucun");
      }

      // Si "Aucun" est sélectionné, désélectionner tous les autres symptômes
      if (symptom === "Aucun") {
        updatedSymptoms.clear();
        updatedSymptoms.add("Aucun");
      }
    }

    setSelectedSymptoms(updatedSymptoms);
  };


  const handleAddCustomSymptom = () => {
    if (customSymptom.trim() && !customSymptomsList.includes(customSymptom.trim())) {
      setCustomSymptomsList([...customSymptomsList, customSymptom.trim()]);
      setSelectedSymptoms(new Set([...Array.from(selectedSymptoms), customSymptom.trim()]));
      setCustomSymptom("");
    }
  };

  const handleSave = () => {
    onSymptomsChange(Array.from(selectedSymptoms));
    onClose();
  };

  return (
    <Modal
      show
      onHide={onClose}
      backdrop="static"
      keyboard={false}
      dialogClassName="symptoms-modal"
      backdropClassName="symptoms-backdrop"
    >
      <Modal.Header closeButton>
        <Modal.Title><h6>{t("Choisir des symptômes")}</h6></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="fs-7">
          {predefinedSymptoms[category]?.map((symptom, idx) => (
            <Form.Check
              key={idx}
              type="checkbox"
              id={`symptom-${idx}`}
              label={symptom}
              checked={selectedSymptoms.has(symptom)}
              onChange={() => handleCheckboxChange(symptom)}
            />
          ))}
          {customSymptomsList.map((custom, idx) => (
            <Form.Check
              key={`custom-${idx}`}
              type="checkbox"
              id={`custom-symptom-${idx}`}
              label={custom}
              checked={selectedSymptoms.has(custom)}
              onChange={() => handleCheckboxChange(custom)}
            />
          ))}
        </div>
        <div className="mt-3">
          <Form.Group controlId="customSymptom" className="fs-7 mb-4">
            <Form.Label>{t("Ajouter un symptôme personnalisé")}</Form.Label>
            <div className="d-flex">
              <Form.Control
                type="text"
                placeholder={t("Nouveau symptôme")}
                value={customSymptom}
                onChange={(e) => setCustomSymptom(e.target.value)}
                className="form-control-sm"
              />
              <Button variant="outline-primary" className="ms-2 btn-sm" onClick={handleAddCustomSymptom}>
                {t("Ajouter")}
              </Button>
            </div>
          </Form.Group>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose} className="btn-sm">
          {t("Annuler")}
        </Button>
        <Button variant="primary" onClick={handleSave} className="btn-sm">
          {t("Enregistrer")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SymptomsSelector;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import InputAutocomplete from '../../helpers/InputAutocomplete/InputAutocomplete';
import { debug } from './../../../utils';
const TreeSpeciesSelector = ({ essence_fr, essence_lt, cultivar = '', onUpdate }) => {
  const treesTaxonomy = useSelector(state => state.config.treesTaxonomy); // Liste d'objets
  const [essenceFr, setEssenceFr] = useState(essence_fr);
  const [essenceLt, setEssenceLt] = useState(essence_lt);
  const [cultivars, setCultivars] = useState([]);
  useEffect(() => {
    setEssenceFr(essence_fr);
    setEssenceLt(essence_lt);
  }, [essence_fr, essence_lt]);
  // Trie les données selon la clé spécifiée
  const sortTreesTaxonomy = (key) => {
    return [...treesTaxonomy].sort((a, b) => a[key].localeCompare(b[key]));
  };
  // Formate une suggestion pour l'affichage
  const formatSuggestion = (name, suggestion) => {
    if (!suggestion) return '';
    switch (name) {
      case 'essence_fr':
        return `${suggestion.essence_fr || ''} (${suggestion.essence_lt || ''})`;
      case 'essence_lt':
        return `${suggestion.essence_lt || ''} (${suggestion.essence_fr || ''})`;
      case 'cultivar':
        return `${suggestion.key || ''}`;
      default:
        console.log(name, suggestion)
        return '';
    }
  };
  // Gère la modification des champs texte
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    if (name === 'essence_fr') { setEssenceFr(value); }
    if (name === 'essence_lt') {
      setEssenceLt(value);
    }
    onUpdate({ [name]: value });
  };
  // Gère la sélection d'une suggestion
  const handleSuggestionSelect = (name, suggestion) => {
    if (!suggestion) return;
    setEssenceFr(suggestion.essence_fr || '');
    setEssenceLt(suggestion.essence_lt || '');
    onUpdate({
      essence_fr: suggestion.essence_fr || '',
      essence_lt: suggestion.essence_lt || '',
    });

    if (['essence_fr', 'essence_lt'].indexOf(name) > -1) {

      const cultivarsObject = suggestion.cultivars ? suggestion.cultivars.map(item => ({
        key: item,
        value: item
      })) : [];

      console.log('-------------')
      console.log(cultivarsObject)
      setCultivars(cultivarsObject)

    }
  };
  return (
    <div className="tree-species-selector">
      <div className="form-group">
        <label className="form-group-label">Essence :</label>
        <InputAutocomplete
          suggestionsSrc={sortTreesTaxonomy('essence_fr')} // Trie par essence_fr
          name="essence_fr"
          value={essenceFr}
          inputClassName="form-control-sm"
          displayKey="essence_fr" // Clé pour afficher les suggestions
          onChange={handleOnChange}
          formatSuggestion={formatSuggestion}
          onSuggestionSelect={handleSuggestionSelect}
          disableFilter={false} // Désactive le filtre interne
        />
      </div>
      <div className="form-group">
        <label className="form-group-label">Nom latin :</label>
        <InputAutocomplete
          suggestionsSrc={sortTreesTaxonomy('essence_lt')} // Trie par essence_lt
          name="essence_lt"
          value={essenceLt}
          inputClassName="form-control-sm"
          displayKey="essence_lt" // Clé pour afficher les suggestions
          onChange={handleOnChange}
          formatSuggestion={formatSuggestion}
          onSuggestionSelect={handleSuggestionSelect}
          disableFilter={false} // Désactive le filtre interne
        />
      </div>
      {essenceLt != '' &&
        <div>
          <label className="form-group-label">Cultivar :</label>
          <input type="text" className='form-control form-control-sm' value={cultivar} name="cultivar" onChange={handleOnChange} />
        </div>
      }
    </div>
  );
};
export default TreeSpeciesSelector;

/*

  
          <InputAutocomplete
            suggestionsSrc={cultivars}
            key={cultivars}
            name="cultivar"
            value={cultivar}
            inputClassName="form-control-sm"
            displayKey="value" // Clé pour afficher les suggestions
            onChange={handleOnChange}
            formatSuggestion={formatSuggestion}
            onSuggestionSelect={handleSuggestionSelect}
            disableFilter={false} // Désactive le filtre interne
            debug={true}
          />
*/
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FaRegPlusSquare } from 'react-icons/fa';
import API from 'api';
import { debug } from 'utils';
// import { setProject } from 'redux/projectSlice';
const ProjectsAdminHomeBloc = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const [customers, setCustomers] = useState([])
  const [projects, setProjects] = useState([])
  const [customerId, setCustomerId] = useState('')
  const [projectId, setProjectId] = useState('')
  useEffect(() => {
    getCustomers();
  },
    []
  );
  useEffect(() => {
    if (customerId != '') {
      getProjects();
    } else {
      setProjects([]);
    }
  },
    [customerId]
  );
  const openProject = () => {
    if (projectId != '') {
      navigate(`/project/map/${projectId}`);
    }
  }
  const getCustomers = async function () {
    try {
      const response = await API.get('/admin/customers');
      setCustomers(response.data);
    } catch (e) {
      console.log(e)
    }
  }
  const getProjects = async function () {
    try {
      const response = await API.get(`/admin/customer/${customerId}/projects`);
      setProjects(response.data);
    } catch (e) {
      console.log(e)
    }
  }
  return (
    user?.userRoles?.Projects &&
    <div id="ProjectsAdminHomeBloc" className="col-md-4 mb-3">
      <div className="card h-100">
        <div className='card-title'>
          <h5 className="card-title ">Projets clients</h5>
        </div>
        <div className="card-body">

          <div className="card-text">
            <div className='mb-3'>
              <select className='form-control form-control-sm' value={customerId} onChange={event => setCustomerId(event.target.value)}>
                <option value="">&raquo; Clients</option>
                {customers &&
                  customers.map(customer => (
                    <option key={customer.custId} value={customer.custId}>{customer.custName}</option>
                  ))
                }
              </select>
            </div>
            {customerId != '' && <div className='input-group'>

              <select className='form-control form-control-sm' value={projectId} onChange={event => setProjectId(event.target.value)}>
                <option value="">&raquo; Projets</option>
                {projects &&
                  projects.map(project => (
                    <option key={project.projId} value={project.projId}>{project.projName}</option>
                  ))
                }
              </select>
              <button className='btn btn-sm btn-outline-primary' onClick={openProject} disabled={projectId == ''}>OK</button>
            </div>
            }
          </div>
        </div>
      </div>

    </div>
  );
};

export default ProjectsAdminHomeBloc;
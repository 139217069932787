import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useLocation, Link, useNavigate, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AiOutlineArrowLeft } from 'react-icons/ai'; // Importe l'icône souhaitée depuis React Icons
import { debug, formatDate } from './../../utils';
import API from '../../api';
import BackLink from '../helpers/backlink/BackLink';
// import OrdersList from '../orders/OrdersList';
// import OrderDetais from '../orders/OrderDetails';
import CustomerAccount from '../customers/CustomerAccount';
import UserParameters from './UserParameters';
import ResponsiveNavTabs from 'components/helpers/ResponsiveNavTabs/ResponsiveNavTabs';
import './user-home.css';
function UserHome() {
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState({});
  const [customer, setCustomer] = useState({});
  // const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [activeTab, setActiveTab] = useState(0);
  // const user = useSelector((state) => state.user);
  useEffect(() => {
  }, [user]
  );
  useEffect(() => {
    API.get('/user/parameters').then(response => setUser(response.data));
    // API.get('/customer').then(response => setCustomer(response.data));
    // API.get('/customer/orders').then(response => setOrders(response.data))
    if (location.hash) {
      if (location.hash.replace('#', '') != activeTab) {
        setActiveTab(location.hash.replace('#', '')); // Met à jour l'état activeTab si tab est présent
      }
    }
    const handleHashChange = (event) => {

      if (location.hash) {
        setActiveTab(location.hash.replace('#', ''));
      }
    };
    window.addEventListener('hashchange', handleHashChange, false);
    return () => {
      window.removeEventListener('hashchange', handleHashChange, false);
    };
  }, []);




  useEffect(() => {
    const newState = { ...window.history.state, tab: activeTab }; // Exemple d'ajout de l'état de l'onglet
    window.history.pushState(newState, '', '#' + activeTab);
  }, [activeTab]);
  const handleOrderSelect = (order) => {

    setSelectedOrder(order);
    setActiveTab('order');
  }
  const handleAccountUpdated = (customer) => {
    setCustomer(customer);
  }
  const handleUserUpdated = (user) => {
    /// setCustomer(customer);

  }
  const handleOrderUpdated = () => {

  }
  return (
    <div id="UserHome">
      <div className='container'>
        <h1>Mon compte</h1>
        <ResponsiveNavTabs tabNames={['Mes paramètres']} activeTab={activeTab} setActiveTab={setActiveTab} />
        {/* 'Mon compte client', */}

        {/* activeTab == 'orders' &&
              <>
                <h2>Mes commandes</h2>
                <OrdersList orders={orders} onSelect={handleOrderSelect}></OrdersList>
              </>
            */}
        {/* activeTab == 'order' &&
              <>
                <div className='mb-2'>
                  <span onClick={e => setActiveTab('orders')}>
                    <AiOutlineArrowLeft size="12" className="me-1" /> <span className='pointer underline-on-hover'>Retour aux commandes</span>
                  </span>
                </div>
                <h2>Commande n° {selectedOrder.ordNumber} du {formatDate(selectedOrder.ordCreatedAt)}</h2>
                <OrderDetais ordId={selectedOrder.ordId} onUpdated={handleOrderUpdated}></OrderDetais>
              </>
          */}
        {/* activeTab == 'account' &&
              <>
                <h2>Mon compte client</h2>
                <CustomerAccount customer={customer} onUpdated={handleAccountUpdated} />
              </>
        */}
        {activeTab == '0' &&
          <>


          </>
        }

        {activeTab == '0' &&
          <div className='nav-tabs-tab'>

            <UserParameters user={user} onUpdated={handleUserUpdated} />
          </div>
        }
        {debug(user, 0)}
      </div>
    </div>
  )
}
export default UserHome
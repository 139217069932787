// CustomMarker.js
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Marker, Popup } from 'react-leaflet';
import useItemsService from './../../hooks/useItemsService';
import { useProjectMapContext } from './../../hooks/useProjectMapContext';
import L from 'leaflet';
const CustomMarker = ({ item }) => {
  const { setItemContextMenuOptions } = useProjectMapContext();
  const { update_item, setItemUnlockTimeout } = useItemsService();
  const isDragging = useRef(false);
  const relockAt = useRef(0);

  const lockedIcon = useMemo(() => L.icon({
    iconUrl: `/img/markers/${item.itType}-lk.png`,
    iconSize: [17, 25],
    iconAnchor: [9, 12]
  }), [item.itType]);
  const unlockedIcon = useMemo(() => L.icon({
    iconUrl: `/img/markers/${item.itType}.png`,
    iconSize: [17, 25],
    iconAnchor: [9, 12]
  }), [item.itType]);







  /*
  
  const intervalId = setInterval(() => {
    */




  return (item.itGeometry && (
    <>
      <Marker
        position={item.itGeometry}
        draggable={item.unlocked}
        icon={item.unlocked ? unlockedIcon : lockedIcon}
        eventHandlers={{
          dragstart: (e) => {
            isDragging.current = true;
          },
          drag: (e) => {
            setItemUnlockTimeout(item.itId, new Date().getTime() + 5000);
          },
          dragend: (e) => {
            const { lat, lng } = e.target.getLatLng();
            update_item({ ...item, itGeometry: [lat, lng] })
          },
          mouseover: (e) => {
            e.target.openPopup()
          },
          mouseout: (e) => {
            e.target.closePopup()
          },
          click: event => {
            setItemContextMenuOptions({
              show: true,
              item: item,
              left: event.originalEvent.layerX - 20,
              top: event.originalEvent.layerY - 20,
              latlng: [event.latlng.lat, event.latlng.lng],
            })
          },
        }}
      >
        <Popup>{item.itName}</Popup>
      </Marker>
    </>
  ));
};
export default CustomMarker;
/*
 - {item.meta['diags/tree/a/mecanique']}
*/
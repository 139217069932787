// ResponsiveNavTabs.fr


import React, { useState, useEffect } from 'react';
import { Nav, Form, Container, Row, Col } from 'react-bootstrap';
import { InputGroup } from 'react-bootstrap';
import './responsive-nav-tabs.css';
const ResponsiveNavTabs = ({ activeTab = 0, tabNames = [], children = null, setActiveTab, forceSelect = false, size = 'sm' }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isMobile = windowWidth < 768;

  const handleSelectChange = (event) => {


  };

  const handleNavSelect = (selectedKey) => {


  };

  return (

    <Row className="align-items-center">
      <Col>
        {(isMobile || forceSelect) ? (
          <div className="d-flex">
            <Form.Select size={size} value={activeTab} onChange={event => setActiveTab(parseInt(event.target.value, 10))} className="me-auto mb-2">
              {tabNames.map((name, index) => (
                <option key={index} value={index}>{name}</option>
              ))}
            </Form.Select>
            {children && <div className='ms-3 d-flex justify-content-end align-items-center'>{children}</div>}
          </div>
        ) : (
          <Nav variant="tabs" activeKey={activeTab.toString()} onSelect={idx => setActiveTab(parseInt(idx))}>
            {tabNames.map((name, index) => (
              <Nav.Item key={index}>
                <Nav.Link eventKey={index.toString()}>{name}</Nav.Link>
              </Nav.Item>
            ))}
            {children && <div className='flex-grow-1 d-flex justify-content-end align-items-center'>{children}</div>}
          </Nav>
        )}
      </Col>

    </Row>

  );
};

export default ResponsiveNavTabs;

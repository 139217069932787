import React from 'react';
import { useTranslation } from 'react-i18next';
const Indicator = ({ type, value, labelKey, qualification = '', size = 20 }) => {
  const indicatorValue = value || '0'; // Valeur par défaut "0"
  const { t } = useTranslation();



  return (
    <span
      className="d-flex align-items-center justify-content-center"
      style={{ width: `${size + 2}px`, height: `${size + 2}px` }}
    >
      <img
        src={`/img/indicators/${type}_${indicatorValue}.png`}
        alt={`${labelKey} : ${t(`${type}_${indicatorValue}`)}`}
        title={`${labelKey} : ${t(`${type}_${indicatorValue}`)}`}
        style={{ width: `${size}px`, height: `${size}px` }}
      />
    </span>

  );
};

export default Indicator;